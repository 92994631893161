import { useEffect, useState } from "react";
import { createUser } from "src/api/auth";
import { emailRegex, minPasswordLength } from "src/constants/common";
import { ErrorObject, errorsFields, parseError } from "src/utils/error/error";

const emptyError = {} as ErrorObject;

export const useRegistration = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorObject>(emptyError);

  useEffect(() => {}, []);

  const writeData = () => {
    // console.log("white data", data);
    // todo save to global
  };

  const checkError = (error) => {
    if (error) {
      setError(parseError(error));
      return true;
    }
    return false;
  };

  const validate = (email, password, passwordConfirmation) => {
    if (!email) {
      setError({ ...parseError("empty-fields"), type: errorsFields.email });
      return false;
    }

    if (!password) {
      setError({ ...parseError("empty-fields"), type: errorsFields.password });
      return false;
    }

    if (!email.match(emailRegex)) {
      setError(parseError("invalid-email"));
      return false;
    }

    if (passwordConfirmation !== password) {
      setError(parseError("password-match"));
      return false;
    }

    if (password.length < minPasswordLength) {
      setError({
        ...parseError("password-length"),
        type: errorsFields.password,
      });
      return false;
    }
    if (passwordConfirmation.length < minPasswordLength) {
      setError({
        ...parseError("password-length"),
        type: errorsFields.passwordConfirmation,
      });
      return false;
    }

    return true;
  };

  const initUserRegistration = async ({
    email,
    password,
    passwordConfirmation,
  }) => {
    setIsLoading(true);

    setError(emptyError);

    if (!validate(email, password, passwordConfirmation)) {
      setIsLoading(false);
      return null;
    }

    const [, error] = await createUser(email, password);

    setIsLoading(false);

    if (checkError(error)) writeData();

    return null;
  };

  return { isLoading, error, initUserRegistration };
};
