import React from "react";
import { Text } from "../Typography";

interface ListItemProps {
  title: string;
  value: string | number;
}

export const ListItem = ({ title, value }: ListItemProps) => {
  return (
    <div className="flex flex-row justify-between">
      <Text text={`${title}: `} />
      <Text text={`${value} грн`} />
    </div>
  );
};
