import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/global";
import {
  getLikedProducts,
  removeLikedProduct,
  setLikedProduct,
} from "src/utils/local-storage/product";

export const useLiked = (id: string | undefined = "") => {
  const [liked, setLiked] = useState<boolean>(false);

  const { likedProducts, updateContext } = useContext(GlobalContext);

  const toggleLike = () => {
    if (!id) return;
    if (liked) removeLikedProduct(id);
    if (!liked) setLikedProduct(id);
    // @ts-ignore
    updateContext({
      likedProducts: [...getLikedProducts()],
    });
  };

  useEffect(() => {
    if (!id) return;
    if (likedProducts?.includes(id)) setLiked(true);
    else setLiked(false);
  }, [id, likedProducts?.length]);

  return { liked, amount: likedProducts?.length, toggleLike };
};
