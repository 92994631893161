import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import ua from "../../locales/solodki/ua.json";
import ru from "../../locales/solodki/ru.json";
import en from "../../locales/solodki/en.json";

export enum Languages {
  ua = "ua",
  ru = "ru",
  en = "en",
}

const DEFAULT_LOCALE = Languages.ua;

const loadL10nBundle = (locale) => {
  if (locale === Languages.ua) return Promise.resolve(ua);
  if (locale === Languages.ru) return Promise.resolve(ru);
  if (locale === Languages.en) return Promise.resolve(en);

  return Promise.resolve(ua);
};

const backendOptions = {
  loadPath: "{{lng}}|{{ns}}", // used to pass language and namespace to custom XHR function
  ajax: (url, options, callback) => {
    // instead of loading from a URL like i18next-xhr-backend is intended for, we repurpose this plugin to
    // load webpack chunks instead by overriding the ajax behavior
    // it's easier to use webpack in our current CRA to dynamically import a JSON with the translations
    // than to update and serve a static folder with JSON files on the CDN with cache invalidation
    try {
      const [lng] = url.split("|");

      // this mocks the XHR behavior so it works with the backend AJAX pattern in this XHR library
      loadL10nBundle(lng).then((data) => {
        callback(JSON.stringify(data), {
          status: 200, // status code is required by XHR plugin to determine success or failure
        });
      });
    } catch (e) {
      // logError(e, "i18next");
      callback(null, {
        status: 500,
      });
    }
  },
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const i18nInit = () =>
  i18next
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(XHR)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      ns: ["solodki"],
      defaultNS: "solodki",
      lng: DEFAULT_LOCALE,
      // fallbackLng: "ua",
      // lng: "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option
      // fallbackLng: "en",
      // debug: true,
      supportedLngs: Object.keys(Languages),
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: backendOptions,
    });

export default i18nInit;
