import { createContext } from "react";

export interface AppContextType {
  likedProducts: Array<string>;
  productCard: any;
  user: any;
  updateContext: (newContext: any) => void;
}

export const GlobalContext = createContext<Partial<AppContextType>>({});
