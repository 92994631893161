import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as InstagramIcon } from "src/assets/instagram.svg";
import { ReactComponent as FacebookIcon } from "src/assets/facebook.svg";
import { ReactComponent as TelegramIcon } from "src/assets/telegram.svg";
import { ReactComponent as ViberIcon } from "src/assets/viber.svg";
import { ReactComponent as Logo } from "src/assets/logo.svg";

import { Text, TextH4 } from "../Typography";
import { Button, ButtonTypes } from "../button";

interface MenuProps {
  title?: string;
  style?: string;
  children: ReactNode;
}

const MenuContent = ({ title, children, style }: MenuProps) => {
  const md = "md:basis-1/2";
  const lg = "lg:pr-0 lg:w-fit lg:mr-16 lg:basis-2/6";
  const xl = "xl:w-12 xl:w-fit xl:mr-0 xl:basis-3/12";
  const xxl = "xxl:mr-0";

  return (
    <div className={`flex mt-16 flex-col ${md} ${lg} ${xl} ${xxl} ${style}`}>
      {title && <TextH4 text={title} style="text-white mb-20px" />}
      {children}
    </div>
  );
};

export const Footer = () => {
  const textStyle = "text-white mb-2.5 cursor-pointer";

  const { t } = useTranslation();

  const navigate = useNavigate();

  const customersData = {
    title: t("footer.customersInfo.title", "ПОКУПАТЕЛЯМ"),
    items: [
      {
        name: t("footer.customersInfo.paymentAndDelivery", "Оплата и доставка"),
        path: "/",
      },
      {
        name: t("footer.customersInfo.refund", "Обмен и возврат"),
        path: "/",
      },
      {
        name: t("footer.customersInfo.about", "О компании"),
        path: "/about",
      },
      {
        name: t("footer.customersInfo.questions", "Частые вопросы"),
        path: "/",
      },
    ],
  };

  const workingHoursData = {
    title: t("footer.workingHours.title", "ГРАФИК РАБОТЫ"),
    items: [
      { name: t("footer.workingHours.mondayFriday", "Пн - Пт 10.00 - 21.00") },
      { name: t("footer.workingHours.saturday", "Сб  10.00 - 17.00") },
      { name: t("footer.workingHours.sunday", "Вс - выходной") },
    ],
  };

  const navigateTo = (path: string) => navigate(path);

  return (
    <div className="flex max-w-xxl justify-center items-center flex-col bg-black xxl:w-xxl xxl:px-48 xl:px-20 lg:px-12 md:px-7">
      <div className="flex flex-1 w-full flex-row flex-nowrap border-b border-camel-border justify-start pb-16">
        <div className="sm:hidden lg:list-item lg:pr-105px xxl:mr-0 md:pl-0 lg:w-fit py-16 cursor-pointer">
          <a href="/">
            <Logo color="white" />
          </a>
        </div>
        <div className="flex flex-1 flex-row flex-wrap sm:justify-between lg:justify-start sm:max-w-600px xl:pl-0">
          <MenuContent
            style="order-1 sm:pl-5 md:pl-0 sm:basis-1/2"
            title={customersData.title}
          >
            {customersData.items.map(({ name, path }) => (
              <Text
                key={name}
                text={name}
                style={textStyle}
                onClick={() => navigateTo(path)}
              />
            ))}
          </MenuContent>
          <MenuContent
            style="order-2 sm:px-5 md:px-0"
            title={t("footer.contacts.title", "КОНТАКТЫ")}
          >
            {[
              <Text
                key="solodkishop@gmail.com"
                style={textStyle}
                text="solodkishop@gmail.com"
              />,
              <Text
                key="+380 350 250 35 35"
                style={textStyle}
                text="+380 350 250 35 35"
              />,
              <div key="icons" className="flex mt-2.5 space-x-30px">
                <a href="/">
                  <InstagramIcon />
                </a>
                <a href="/">
                  <FacebookIcon />
                </a>
                <a href="/">
                  <TelegramIcon />
                </a>
                <a href="/">
                  <ViberIcon />
                </a>
              </div>,
            ]}
          </MenuContent>
          <MenuContent
            style="md:order-3 sm:order-1 sm:pr-5"
            title={workingHoursData.title}
          >
            {workingHoursData.items.map(({ name }) => (
              <Text key={name} text={name} style={textStyle} />
            ))}
          </MenuContent>
          <MenuContent
            style="sm:order-first md:order-1 xl:order-4 sm:w-full sm:px-5 md:px-0 lg:basis-auto xl:basis-auto sm:basis-auto"
            title={t("footer.news.title", "БУДЬТЕ В КУРСЕ НАШИХ НОВОСТЕЙ")}
          >
            <div>Iнпут</div>
            <div className="flex justify-center">
              <Button
                type={ButtonTypes.Camel}
                style="w-full"
                text={t("footer.news.buttonSubscribe", "Подписаться")}
              />
            </div>
          </MenuContent>
        </div>
      </div>
      <div className="flex w-full justify-end xxl:pr-135px xl:pr-105px md:pr-16 sm:px-5">
        <Text
          text={t("footer.copyright", "© SOLODKI 2021 Все права защищены")}
          style="text-white py-5"
        />
      </div>
    </div>
  );
};
