import React, { ReactChild } from "react";
import { useLocation } from "react-router";

export const Page = ({ children }: { children: ReactChild }) => {
  const location = useLocation();

  if (location.pathname === "/") return <div>{children}</div>;

  return (
    <div className="flex w-full justify-center">
      <div className="flex relative flex-col w-full max-w-xxl xxl:w-xxl xxl:px-48 xl:px-20 lg:px-12 md:px-7">
        {children}
      </div>
    </div>
  );
};
