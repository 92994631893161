import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { useLiked } from "src/hooks/useLiked";
import { useProductCard } from "src/hooks/useProductCard";
import { useLoginModule } from "src/hooks/useLoginModule";
import { useRegistrationModule } from "src/hooks/useRegistrationModule";
import { useMobileSidebar } from "src/hooks/useMobileSidebar";
import { useUser } from "src/hooks/useUser";

import { ReactComponent as LogoIcon } from "src/assets/logo.svg";
import { ReactComponent as LeftArrowIcon } from "src/assets/left-arrow.svg";

import { ReactComponent as SearchIcon } from "src/assets/search.svg";
import { ReactComponent as HeartIcon } from "src/assets/heart.svg";
import { ReactComponent as BagIcon } from "src/assets/bag.svg";
import { ReactComponent as UserIcon } from "src/assets/user.svg";

import { ReactComponent as BurgerIcon } from "src/assets/burger.svg";

import { Text, TextH3, TextH4, TextH5 } from "../Typography";
import { LanguageSelector } from "../languageSelector";
import { Sidebar } from "../sidebar";

import { Login } from "../Login";
import { Registration } from "../Registration";

export enum HeaderTypes {
  White = "white",
  Black = "black",
  Order = "order",
}

interface HeaderProps {
  type: HeaderTypes;
}

const commonStyles =
  "flex w-full items-center justify-between py-4 border border-white sm:border-b-grey-dark-20";

export const Header = ({ type }: HeaderProps) => {
  const { openProductCard } = useProductCard();

  const navigate = useNavigate();

  const {
    isOpen: isMobileSidebarOpen,
    closeMobileSidebarModule,
    openMobileSidebarModule,
  } = useMobileSidebar();

  const {
    isOpen: isLoginModuleOpen,
    closeLoginModule,
    openLoginModule,
  } = useLoginModule();

  const {
    isOpen: isRegistrationModuleOpen,
    closeRegistrationModule,
    openRegistrationModule,
  } = useRegistrationModule();

  const { amount } = useLiked();

  const { isAuth } = useUser();

  const headerWhiteStyles =
    type === HeaderTypes.White ? "border-none z-10 " : "";

  const textWhite =
    type === HeaderTypes.White
      ? "text-white cursor-pointer"
      : "text-black cursor-pointer";

  const { t } = useTranslation();

  if (type === HeaderTypes.Order)
    return (
      <div
        className={`${commonStyles} sm:w-full lg:w-3/6 lg:border-b-white px-5`}
      >
        <LogoIcon color="black" />
        <div className="flex items-center">
          <LeftArrowIcon color="black" />
          <TextH5
            text={t("header.goBack", "Вернуться в каталог")}
            style="pl-1"
          />
        </div>
      </div>
    );

  const openLoginFromRegistration = () => {
    closeRegistrationModule();
    openLoginModule();
  };

  const openRegistrationFromLogin = () => {
    closeLoginModule();
    openRegistrationModule();
  };

  const toPage = (path) => navigate(path);

  return (
    <>
      <Login
        isOpen={isLoginModuleOpen}
        closeLoginModule={closeLoginModule}
        openRegistrationModule={() => openRegistrationFromLogin()}
      />
      <Registration
        isOpen={isRegistrationModuleOpen}
        closeRegistrationModule={closeLoginModule}
        openLoginModule={() => openLoginFromRegistration()}
      />
      {isMobileSidebarOpen && (
        <Sidebar
          direction="Left"
          onClose={() => closeMobileSidebarModule()}
          content={
            <div className="flex flex-1 flex-col py-7 w-full space-y-7 items-center text-black">
              <TextH3
                onClick={() => toPage(isAuth ? "/profile" : "/login")}
                text={t("header.profile", "Профиль")}
              />
              <TextH3 text={t("header.category", "Категории")} />
              <TextH3 text={t("header.newArrivals", "Новинки")} />
              <TextH3 text={t("header.discount", "Скидки")} />
              <LanguageSelector
                color="black"
                containerStyle="flex pt-10 "
                textStyle="text-black cursor-pointer"
              />
            </div>
          }
        />
      )}
      <div className={`${commonStyles} ${headerWhiteStyles} sm:px-5 md:px-0`}>
        <div className="flex xl:space-x-12 lg:space-x-7 sm:hidden lg:block">
          <TextH4 style={textWhite} text={t("header.category", "Категории")} />
          <TextH4 style={textWhite} text={t("header.newArrivals", "Новинки")} />
          <TextH4 style={textWhite} text={t("header.discount", "Скидки")} />
        </div>
        <div
          onClick={() => openMobileSidebarModule()}
          className="lg:hidden sm:block"
        >
          <BurgerIcon color={type} />
        </div>
        <div className="flex cursor-pointer">
          <LogoIcon color={type} />
        </div>
        <div className="flex xl:space-x-12 lg:space-x-7 sm:space-x-5">
          <LanguageSelector
            color={type === HeaderTypes.White ? "white" : "black"}
            containerStyle="sm:hidden xl:flex"
            textStyle={textWhite}
          />
          <div className="flex items-center cursor-pointer">
            <SearchIcon color={type} />
          </div>
          <div className="flex items-center cursor-pointer">
            <HeartIcon color={type} fill="none" />
            <Text text={`(${amount})`} style={`${textWhite} pl-1`} />
          </div>
          <div
            onClick={openProductCard}
            className="flex items-center cursor-pointer"
          >
            <BagIcon color={type} />
            <Text text={`(${0})`} style={`${textWhite} pl-1`} />
          </div>
          <div
            onClick={isAuth ? () => toPage("/profile") : openLoginModule}
            className="sm:hidden lg:block cursor-pointer"
          >
            <UserIcon color={type} />
          </div>
        </div>
      </div>
    </>
  );
};
