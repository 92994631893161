import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/global";
import {
  getProductsInCard,
  removeProductFromCard,
  setProductInCard,
} from "src/utils/local-storage/product";

export const useProductCard = (id: string | undefined = "") => {
  const [isInProductCard, setIsProductInCard] = useState<boolean>(false);

  const {
    productCard,
    productCard: { products, show } = { products: [], show: false },
    updateContext,
  } = useContext(GlobalContext);

  const toggleAddToCard = (currentId) => {
    if (!id || !currentId) return;
    if (isInProductCard) removeProductFromCard(currentId || id);
    if (!isInProductCard) setProductInCard(currentId || id);
    // @ts-ignore
    updateContext({
      productCard: {
        ...productCard,
        products: [...getProductsInCard()],
      },
    });
  };

  const updateShowContext = (show) => {
    // @ts-ignore
    updateContext({
      productCard: {
        ...productCard,
        show,
      },
    });
  };

  const openProductCard = () => updateShowContext(true);
  const closeProductCard = () => updateShowContext(false);

  const removeFromCard = (id) => toggleAddToCard(id);

  useEffect(() => {
    if (!id) return;
    if (products?.includes(id)) setIsProductInCard(true);
    else setIsProductInCard(false);
  }, [id, products?.length]);

  return {
    show,
    isInProductCard,
    amount: products?.length,
    productInCard: products,
    toggleAddToCard,
    openProductCard,
    closeProductCard,
    removeFromCard,
  };
};
