import React, { ReactElement } from "react";

import { TextH4 } from "src/components/Typography";

export enum ButtonTypes {
  Black = "black",
  Camel = "camel",
}

interface ButtonProps {
  type: ButtonTypes;
  text: string;
  style?: string;
  loading?: boolean;
  onClick?: Function;
}

export const Button = ({
  type,
  text,
  style = "",
  loading = false,
  onClick = () => null,
}: ButtonProps): ReactElement => {
  const cummonStyles =
    "flex cursor-pointer justify-center items-center min-w-[250px] h-[55px] hover:border";

  const camelStyle =
    (type === ButtonTypes.Camel &&
      "bg-camel-dark text-white hover:border-camel-dark hover:bg-white hover:text-camel-dark") ||
    "";
  const blackStyle =
    (type === ButtonTypes.Black &&
      "bg-black text-white hover:border-black hover:bg-white hover:text-black") ||
    "";

  return (
    <div
      className={`${cummonStyles} ${camelStyle} ${blackStyle} ${style}`}
      onClick={() => onClick()}
    >
      <TextH4 text={loading ? "...loading" : text} />
    </div>
  );
};
