import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import "./firebase.config";

const auth = getAuth();

const provider = new GoogleAuthProvider();

const defaultErrorMessage = "unknown error";

export const createUser = async (email: string, password: string) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    return [user, null];
  } catch (error: any) {
    return [null, error?.message || defaultErrorMessage];
  }
};

export const loginUser = async (email: string, password: string) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    return [user, null];
  } catch (error: any) {
    return [null, error?.message || defaultErrorMessage];
  }
};

export const signInWithGoogle = async () => {
  try {
    const { user } = await signInWithPopup(auth, provider);
    return [user, null];
  } catch (error: any) {
    return [null, error?.message || defaultErrorMessage];
  }
};

export const logOut = async () => {
  try {
    const data = await signOut(auth);
    return [data, null];
  } catch (error: any) {
    return [null, error?.message || defaultErrorMessage];
  }
};
