import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CrossIcon } from "src/assets/cross.svg";
import { useRegistration } from "src/hooks/useRegistration";
import { errorsFields } from "src/utils/error/error";

import { Button, ButtonTypes } from "../button";
import { Input } from "../Input";
import { TextH1, TextH2, TextH5 } from "../Typography";

const initialData = {
  email: "",
  password: "",
  passwordConfirmation: "",
};

enum RegistrationFields {
  email = "email",
  password = "password",
  passwordConfirmation = "passwordConfirmation",
}

type RegistrationUserProps = {
  [key in RegistrationFields]: string;
};

interface RegistrationModalProps {
  isOpen: boolean;
  closeRegistrationModule: Function;
  openLoginModule: Function;
}

export const Registration = ({
  isOpen,
  closeRegistrationModule,
  openLoginModule,
}: RegistrationModalProps) => {
  const [data, setData] = useState<RegistrationUserProps>(initialData);

  const { t } = useTranslation();

  const { isLoading, error, initUserRegistration } = useRegistration();

  if (!isOpen) return null;

  const onSubmit = (key, value) => {
    const d = { ...data };
    d[key] = value;
    setData(d);
  };

  const onPressRegistration = () => {
    initUserRegistration(data);
  };

  return (
    <div className="flex fixed left-0 justify-center items-center w-screen h-screen z-20 bg-black-30">
      <div className="flex relative">
        <div
          onClick={() => closeRegistrationModule()}
          className="flex absolute right-5 top-5 cursor-pointer"
        >
          <CrossIcon />
        </div>
        <div className="flex flex-col md:w-[700px] md:h-[650px] sm:w-screen sm:h-screen bg-white lg:py-7 md:py-16 sm:py-24 ">
          <div className="flex w-full justify-center">
            <TextH2
              text={t("registration.title", "Регистрация")}
              style="text-black sm:hidden md:block cursor-default"
            />
            <TextH1
              text={t("registration.title", "Регистрация")}
              style="text-black sm:block md:hidden"
            />
          </div>
          <div className="flex w-full items-center flex-col mt-7">
            <div className="flex md:w-1/2 sm:w-full sm:px-5 md:px-0 items-center justify-center flex-col space-y-5">
              <Input
                error={
                  error.type === errorsFields.email || error.type === ""
                    ? error.message
                    : ""
                }
                type="email"
                style="w-full"
                label={t("registration.email", "Логин")}
                onSubmit={(v) => onSubmit(RegistrationFields.email, v)}
              />
              <Input
                error={
                  error.type === errorsFields.password || error.type === ""
                    ? error.message
                    : ""
                }
                label={t("registration.password", "Пароль")}
                style="w-full"
                type="password"
                onSubmit={(v) => onSubmit(RegistrationFields.password, v)}
              />
              <Input
                error={
                  error.type === errorsFields.passwordConfirmation ||
                  error.type === ""
                    ? error.message
                    : ""
                }
                label={t("registration.password", "Подтверждение пароля")}
                style="w-full"
                type="password"
                onSubmit={(v) => {
                  onSubmit(RegistrationFields.passwordConfirmation, v);
                }}
              />
            </div>
            <div className="flex justify-center mt-3 w-full">
              <div className="flex md:w-1/2 sm:w-full sm:px-5 md:px-0 justify-between">
                <TextH5
                  onClick={() => openLoginModule()}
                  text={t("registration.login", "Вход")}
                  style="text-black cursor-pointer"
                />
              </div>
            </div>
            <div className="flex w-full justify-center mt-7">
              <div className="flex md:w-1/2 sm:w-full sm:px-5 md:px-0 justify-end">
                <Button
                  loading={isLoading}
                  onClick={onPressRegistration}
                  type={ButtonTypes.Black}
                  text={t("registration.button", "Создать аккаунт")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
