export const writeAuth = (auth) => {
  // eslint-disable-next-line
  localStorage.setItem("authData", JSON.stringify(auth));
};

export const getAuth = () => {
  // eslint-disable-next-line
  const auth = localStorage.getItem("authData");

  if (auth) return JSON.parse(auth);

  return null;
};
