import { useCallback, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { ReactNotifications } from "react-notifications-component";
import { Router } from "./router";

import { getLocale } from "./utils/local-storage/locale";

import { GlobalContext } from "./context/global";
import { getLikedProducts } from "./utils/local-storage/product";
import { getAuth } from "./utils/local-storage/auth";

import "react-notifications-component/dist/theme.css";

const App = () => {
  const [globalContext, setGlobalContext] = useState({
    likedProducts: getLikedProducts(),
    auth: getAuth(),
  });

  const { i18n } = useTranslation("solodki", { useSuspense: false });

  const locale = getLocale();

  const updateContext = useCallback(
    (newContext) => {
      setGlobalContext({
        ...globalContext,
        ...newContext,
      });
    },
    [globalContext]
  );

  if (i18n.language !== locale) i18n.changeLanguage(locale);
  // eslint-disable-next-line
  document.documentElement.lang = i18n.language;

  return (
    <I18nextProvider i18n={i18n}>
      <GlobalContext.Provider value={{ ...globalContext, updateContext }}>
        <ReactNotifications />
        <Router />
      </GlobalContext.Provider>
    </I18nextProvider>
  );
};

export default App;
