// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcp0g7ihG0U0NcNcXn_2UWYsfVTxfJ3GM",
  authDomain: "solodki-29ca7.firebaseapp.com",
  databaseURL:
    "https://solodki-29ca7-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "solodki-29ca7",
  storageBucket: "solodki-29ca7.appspot.com",
  messagingSenderId: "323163203126",
  appId: "1:323163203126:web:caa9e445b1990ce1b99a97",
  measurementId: "G-1MDP7B2FRW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

export default app;
