import { useState } from "react";

export const useMobileSidebar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openMobileSidebarModule = () => setIsOpen(true);
  const closeMobileSidebarModule = () => setIsOpen(false);

  return { isOpen, openMobileSidebarModule, closeMobileSidebarModule };
};
