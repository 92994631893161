import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "src/context/global";

export const useUser = () => {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  const { user } = useContext(GlobalContext);

  const checkAuth = () => {
    if (!user) return false;

    const { expirationTime } = user?.stsTokenManager;
    if (new Date().getTime() > expirationTime) return false;
    return true;
  };

  useEffect(() => {
    setIsAuth(checkAuth());
  }, [user]);

  return { isAuth, user };
};
