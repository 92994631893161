import * as React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Header, HeaderTypes } from "./components/Header";

const Home = React.lazy(() => import("./pages/Home/Home"));
const About = React.lazy(() => import("./pages/About"));
const Policy = React.lazy(() => import("./pages/Policy"));
const Delivery = React.lazy(() => import("./pages/Delivery"));
const Product = React.lazy(() => import("./pages/Product"));
const Support = React.lazy(() => import("./pages/Support"));
const ProductList = React.lazy(() => import("./pages/ProductList"));

const Profile = React.lazy(() => import("./pages/Profile"));
const Orders = React.lazy(() => import("./pages/Orders"));

export const NotFoundLazy = React.lazy(() => import("./pages/NotFound"));

const WithHeader = ({
  children,
  headerType,
}: {
  children: JSX.Element;
  headerType?: HeaderTypes;
}) => (
  <>
    {headerType && <Header type={headerType} />}
    {children}
  </>
);

export const commonRoutes = [
  {
    name: "Home",
    index: true,
    path: "/",
    element: <Home />,
  },
  {
    name: "About",
    path: "/about",
    element: <About />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "Policy",
    path: "/policy",
    element: <Policy />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "Delivery",
    path: "/delivery",
    element: <Delivery />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "Product",
    path: "/product/:id",
    element: <Product />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "Support",
    path: "/support",
    element: <Support />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "ProductList",
    path: "/products",
    element: <ProductList />,
    headerType: HeaderTypes.Black,
  },
].map(({ headerType, element, ...props }) => ({
  ...props,
  element: <WithHeader headerType={headerType}>{element}</WithHeader>,
}));

const Redirect = ({
  children,
  redirectLink,
  requiredAuth,
}: {
  children: JSX.Element;
  redirectLink: string;
  requiredAuth: boolean;
}) => {
  const auth = true; // useAuth();
  const location = useLocation();

  if (requiredAuth !== auth)
    return <Navigate to={redirectLink} state={{ from: location }} replace />;
  return children;
};

export const privateRoutes = [
  {
    name: "Profile",
    path: "/profile",
    element: <Profile />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "Orders",
    path: "/orders",
    element: <Orders />,
    headerType: HeaderTypes.Order,
  },
].map(({ headerType, element, ...route }) => ({
  ...route,
  element: (
    <Redirect requiredAuth redirectLink="/login">
      <WithHeader headerType={headerType}>{element}</WithHeader>
    </Redirect>
  ),
}));

export const publicRoutes = [
  {
    name: "Login",
    path: "/login",
    element: <Home />,
    headerType: HeaderTypes.Black,
  },
  {
    name: "Registration",
    path: "/registration",
    element: <Home />,
    headerType: HeaderTypes.Black,
  },
].map(({ headerType, element, ...route }) => ({
  ...route,
  element: (
    <Redirect requiredAuth={false} redirectLink="/">
      <WithHeader headerType={headerType}>{element}</WithHeader>
    </Redirect>
  ),
}));
