import { useTranslation } from "react-i18next";
import { ReactComponent as CrossIcon } from "src/assets/cross.svg";
import { useScreen } from "src/hooks/useScreen";
import { Quantity } from "../Quantity";

import { Text, TextH5 } from "../Typography";

interface CardItem {
  id: string;
  image: any;
  name: string;
  size: string;
  color: string;
  qty: number;
  price: number;
}

interface CardItemProps {
  data: CardItem;
  onRemove: Function;
  onUpdateQuantity: Function;
}

export const CardItemComponent = ({
  data: { id, name, size, color, qty, price, image },
  onRemove = () => null,
  onUpdateQuantity = () => null,
}: CardItemProps) => {
  const styleLg = "sm:hidden lg:block";
  const styleSm = "sm:block lg:hidden";

  const { isMobile } = useScreen();

  const { t } = useTranslation();

  const getText = (text) =>
    isMobile ? (
      <TextH5 style={`text-grey font-light ${styleSm}`} text={text} />
    ) : (
      <Text style={`text-grey font-light ${styleLg}`} text={text} />
    );

  return (
    <div className="flex max-w-full py-5">
      <div className="mr-5 lg:w-[120px] sm:w-[75px] h-full ">
        <img
          style={{ objectFit: "contain" }}
          src={image}
          alt={name + size + color}
        />
      </div>
      <div className="flex flex-1 flex-col justify-between">
        <div>
          <div className="flex flex-row flex-nowrap justify-between break-words ">
            <Text text={name} style="max-w-[200px] break-words font-light" />
            <div onClick={() => onRemove(id)} className="flex">
              <CrossIcon />
            </div>
          </div>
          <div className="flex flex-col lg:space-y-[5px] sm:mt-2.5">
            {/* {getText(`${t("productCard.item.acticle", "Артикул")} ${id}`)} */}
            <div className="flex items-center">
              {getText(t("productCard.item.size", "Размер:"))}
              <Text text={size} style="pl-1" />
            </div>
            <div className="flex items-end">
              {getText(t("productCard.item.color", "Цвет:"))}
              <Text text={color} style="pl-1" />
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between pt-2.5">
          <Quantity number={qty} onChange={onUpdateQuantity} />
          <Text style="font-bold" text={`${Number(price) * Number(qty)} грн`} />
        </div>
      </div>
    </div>
  );
};
