import React from "react";
import { useTranslation } from "react-i18next";
import { useProductCard } from "src/hooks/useProductCard";

import Image1 from "src/assets/images/1.png";
import Image2 from "src/assets/images/2.png";
import Image3 from "src/assets/images/3.png";

import { ReactComponent as IconCross } from "src/assets/cross.svg";
import { TextH4 } from "../Typography";
import { CardItemComponent } from "./CardItem";
import { Divider } from "../divider";
import { ListItem } from "./ListItem";
import { Button, ButtonTypes } from "../button";

const mockProducts = [
  {
    id: "124232",
    name: "Расклешенное платье из прошвы",
    size: "xl",
    color: "red",
    qty: 3,
    price: 600,
    image: Image1,
  },
  {
    id: "124233",
    name: "Платье из софта с принтом",
    size: "xl",
    color: "red",
    qty: 2,
    price: 600,
    image: Image2,
  },
  {
    id: "124234",
    name: "test",
    size: "xl",
    color: "red",
    qty: 1,
    price: 600,
    image: Image3,
  },
  {
    id: "124234",
    name: "test",
    size: "s",
    color: "green",
    qty: 1,
    price: 600,
    image: Image3,
  },
];

export const ProductCard = () => {
  const {
    show,
    productInCard = mockProducts,
    amount = 3,
    toggleAddToCard,
    closeProductCard,
  } = useProductCard();

  const { t } = useTranslation();

  if (!show) return null;

  const onUpdateQty = (id, qty) => {
    if (!qty) toggleAddToCard(id);
  };

  const calculatePrice = () => {
    let allPrice = 0;
    productInCard.map(({ price, qty }) => {
      allPrice += price * qty;
      return null;
    });
    return allPrice;
  };

  const getDiscount = () => 300;

  const calculateSummary = () => calculatePrice() - getDiscount();

  const priceList = [
    {
      title: t("productCard.footer.products", "Товары"),
      value: calculatePrice(),
    },
    {
      title: t("productCard.footer.discount", "Скидка"),
      value: getDiscount(),
    },
    {
      title: t("productCard.footer.delivery", "Доставка"),
      value: 0,
    },
    {
      title: t("productCard.footer.summary", "Сумма к оплате"),
      value: calculateSummary(),
    },
  ];

  return (
    <div className="flex fixed z-20 h-screen sm:w-full">
      <div className="flex-1 bg-black-30 sm:hidden lg:flex" />
      <div className="flex top-0 right-0 flex-col w-max lg:min-w-[450px] lg:max-w-[450px] sm:min-w-full h-full justify-between bg-white">
        <div className="flex w-full justify-between items-center p-7 border border-b-black">
          <TextH4
            text={`${t("productCard.header.title", "КОРЗИНА")} (${
              amount || 0
            }) ${t("productCard.header.qty", "товарa")}`}
          />

          <div onClick={closeProductCard}>
            <IconCross />
          </div>
        </div>
        <div className="flex flex-col px-7 justify-start overflow-y-scroll">
          {productInCard.map((product) => (
            <div>
              <CardItemComponent
                data={product}
                onRemove={toggleAddToCard}
                onUpdateQuantity={(qty) => onUpdateQty(product.id, qty)}
              />
              <Divider />
            </div>
          ))}
        </div>

        <div className="flex w-full flex-col p-7">
          <div className="flex flex-col space-y-[5px]">
            {priceList.map((item) => (
              <ListItem {...item} />
            ))}
          </div>
          <Button
            style="mt-4"
            type={ButtonTypes.Black}
            text={t("productCard.footer.submit", "Оформить заказ")}
          />
        </div>
      </div>
    </div>
  );
};
