import { useState } from "react";

export const useLoginModule = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openLoginModule = () => setIsOpen(true);
  const closeLoginModule = () => setIsOpen(false);

  return { isOpen, openLoginModule, closeLoginModule };
};
