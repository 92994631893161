import { useEffect, useState } from "react";

enum ScreenTypes {
  mobile = "320",
  tablet = "640",
  desktop = "1000",
  desktopLarge = "1600",
}

export const useScreen = () => {
  const [type, setType] = useState<ScreenTypes>(ScreenTypes.desktop);

  const checkForWidth = () => {
    // eslint-disable-next-line
    const windowWidth = window.screen.width;

    if (windowWidth > Number(ScreenTypes.desktopLarge)) {
      setType(ScreenTypes.desktopLarge);
      return;
    }
    if (windowWidth > Number(ScreenTypes.desktop)) {
      setType(ScreenTypes.desktop);
      return;
    }
    if (windowWidth > Number(ScreenTypes.tablet)) {
      setType(ScreenTypes.tablet);
      return;
    }
    if (windowWidth > Number(ScreenTypes.mobile)) {
      setType(ScreenTypes.mobile);
    }
  };

  useEffect(() => {
    checkForWidth();
  }, []);

  // eslint-disable-next-line
  window.addEventListener("resize", checkForWidth);

  return {
    isMobile: type === ScreenTypes.mobile,
    isTablet: type === ScreenTypes.tablet,
    isDesktopLarge: type === ScreenTypes.desktopLarge,
    isDesktop: type === ScreenTypes.desktop,
  };
};
