import { useContext, useState } from "react";
import { Store } from "react-notifications-component";
import { loginUser, signInWithGoogle } from "src/api/auth";
import { emailRegex, minPasswordLength } from "src/constants/common";
import { GlobalContext } from "src/context/global";
import { ErrorObject, errorsFields, parseError } from "src/utils/error/error";
import { writeAuth } from "src/utils/local-storage/auth";

const emptyError = {} as ErrorObject;

export const useLogin = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorObject>(emptyError);

  const { updateContext } = useContext(GlobalContext);

  const writeData = (data) => {
    Store.addNotification({
      title: "Success!",
      type: "success",
      container: "top-right",
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });

    const user = {
      uid: data.uid,
      email: data.email,
      emailVerified: data.emailVerified,
      phoneNumber: data.phoneNumber,
      photoURL: data.photoURL,
      stsTokenManager: data.stsTokenManager,
    };
    writeAuth(user);
    // @ts-ignore
    updateContext({
      user,
    });
  };

  const checkError = (error) => {
    if (error) {
      setError(parseError(error));
      return true;
    }
    return false;
  };

  const validate = (email, password) => {
    if (!email) {
      setError({ ...parseError("empty-fields"), type: errorsFields.email });
      return false;
    }

    if (!password) {
      setError({ ...parseError("empty-fields"), type: errorsFields.password });
      return false;
    }

    if (!email.match(emailRegex)) {
      setError(parseError("invalid-email"));
      return false;
    }

    if (password.length < minPasswordLength) {
      setError(parseError("password-length"));
      return false;
    }

    return true;
  };

  const initLoginUser = async ({ email, password }) => {
    setIsLoading(true);

    setError(emptyError);

    if (!validate(email, password)) {
      setIsLoading(false);
      return null;
    }

    const [data, error] = await loginUser(email, password);

    setIsLoading(false);

    if (!checkError(error)) writeData(data);

    return null;
  };

  const initSignInWithGoogle = async () => {
    setIsLoading(true);

    setError(emptyError);

    const data = await signInWithGoogle();
    setIsLoading(false);

    if (!checkError(error)) writeData(data);

    return null;
  };

  return { isLoading, error, initLoginUser, initSignInWithGoogle };
};
