import { Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import { Footer } from "src/components/Footer";
import { Loader } from "./components/loader";
import { Page } from "./components/page";
import { ProductCard } from "./components/productCard";

import {
  commonRoutes,
  NotFoundLazy,
  privateRoutes,
  publicRoutes,
} from "./routes";

export const Router = () => (
  <Routes>
    <Route
      path="/"
      element={
        <>
          <ProductCard />
          <Page>
            <Outlet />
          </Page>
          <Footer />
        </>
      }
    >
      {[...commonRoutes, ...privateRoutes, ...publicRoutes].map(
        ({ element, path, name }) => (
          <Route
            key={path}
            element={
              <Suspense key={name} fallback={<Loader />}>
                {element}
              </Suspense>
            }
            path={path}
          />
        )
      )}
      <Route path="*" element={<NotFoundLazy />} />
    </Route>
  </Routes>
);
