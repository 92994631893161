import { useState } from "react";

export const useRegistrationModule = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openRegistrationModule = () => setIsOpen(true);
  const closeRegistrationModule = () => setIsOpen(false);

  return { isOpen, openRegistrationModule, closeRegistrationModule };
};
