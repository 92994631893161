import { createRef, useState } from "react";
import { TextH5 } from "../Typography";

interface InputProps {
  defaultValue?: string;
  type?: "text" | "password" | "email";
  onSubmit?: Function;
  style?: string;
  label?: string;
  required?: boolean;
  error?: string | JSX.Element;
}

export const Input = ({
  defaultValue = "",
  type = "text",
  onSubmit = () => null,
  style = "",
  label = "",
  error = "",
  required = false,
}: InputProps) => {
  const [value, setValue] = useState<string>(defaultValue);

  // eslint-disable-next-line no-undef
  const inputRef = createRef<HTMLInputElement>();

  const onChange = ({ target: { value } }) => {
    setValue(value);
    onSubmit(value);
  };

  const onLabelPress = () => {
    inputRef?.current?.focus();
  };

  return (
    <div className={`flex flex-col items-center justify-center ${style}`}>
      {label && (
        <div
          onClick={() => onLabelPress()}
          className="flex min-w-[270px] w-full flex-row justify-start cursor-pointer"
        >
          <TextH5 text={label} style="text-black" />
          {required && <TextH5 text="*" style="text-red" />}
        </div>
      )}
      <input
        ref={inputRef}
        className={`text-black min-w-[270px] w-full py-[8px] px-[10px] bg-grey-light active:border-black border ${
          error ? "border-red" : "border-black"
        }`}
        type={type}
        onChange={onChange}
        onSubmit={() => onSubmit()}
        value={value}
      />
      {error && <TextH5 text={error} style="text-red" />}
    </div>
  );
};
