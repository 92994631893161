import React from "react";

import { ReactComponent as PlusIcon } from "src/assets/plus.svg";
import { ReactComponent as MinusIcon } from "src/assets/minus.svg";

interface QuantityProps {
  number: number;
  onChange: Function;
}

export const Quantity = ({ number, onChange }: QuantityProps) => {
  const minusClick = () => onChange(number - 1);
  const plusClick = () => onChange(number + 1);

  const buttonStyle =
    "flex w-[30px] h-[30px] items-center justify-center border border-gray cursor-pointer";

  return (
    <div className="flex items-center">
      <div className={buttonStyle} onClick={plusClick}>
        <PlusIcon />
      </div>
      <div className="flex items-center justify-center px-2.5">{number}</div>
      <div className={buttonStyle} onClick={minusClick}>
        <MinusIcon />
      </div>
    </div>
  );
};
