export const getLikedProducts = (): Array<string> => {
  // eslint-disable-next-line
  const liked = localStorage.getItem("likedItems");

  if (liked) return JSON.parse(liked);

  return [];
};

export const setLikedProduct = (id: string) => {
  const alreadyLiked = getLikedProducts();
  if (alreadyLiked.includes(id)) return;
  alreadyLiked.push(id);
  const liked = JSON.stringify(alreadyLiked);
  // eslint-disable-next-line
  localStorage.setItem("likedItems", liked);
};

export const removeLikedProduct = (id: string) => {
  const alreadyLiked = getLikedProducts();

  const liked = JSON.stringify(
    alreadyLiked.filter((currentId) => id !== currentId)
  );
  // eslint-disable-next-line
  localStorage.setItem("likedItems", liked);
};

export const getProductsInCard = (): Array<string> => {
  // eslint-disable-next-line
  const productCard = localStorage.getItem("productCard");

  if (productCard) return JSON.parse(productCard);

  return [];
};

export const setProductInCard = (id: string) => {
  const productsInCard = getProductsInCard();
  if (productsInCard.includes(id)) return;
  productsInCard.push(id);
  const newProductsInCard = JSON.stringify(productsInCard);
  // eslint-disable-next-line
  localStorage.setItem("productCard", newProductsInCard);
};

export const removeProductFromCard = (id: string) => {
  const productsInCard = getProductsInCard();

  const newProductsInCard = JSON.stringify(
    productsInCard.filter((currentId) => id !== currentId)
  );
  // eslint-disable-next-line
  localStorage.setItem("productCard", newProductsInCard);
};
