// TODO add animations https://www.framer.com/docs/examples/

import React, { ReactElement, useState } from "react";

import { ReactComponent as LogoIcon } from "src/assets/logo.svg";
import { ReactComponent as CrossIcon } from "src/assets/cross.svg";

interface SidebarProps {
  content: ReactElement;
  direction: "Left" | "Right";
  onClose: Function;
  logo?: boolean;
}

export const Sidebar = ({
  content,
  direction,
  logo = true,
  onClose,
}: SidebarProps) => {
  const [animation, setAnimation] = useState(`animate-fadeIn${direction}`);

  return (
    <div
      className={`flex flex-col bg-white h-screen animate-faster sm:w-screen z-10 fixed ${animation}`}
    >
      <div className="flex flex-row items-center justify-between p-7">
        {logo && <LogoIcon color="black" />}
        <div
          onClick={() => {
            setAnimation(`animate-fadeOut${direction}`);
            setTimeout(onClose, 500);
          }}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-1">{content}</div>
    </div>
  );
};
