interface TextBase {
  text: string | JSX.Element;
  style?: string;
  onClick?: Function;
}

interface TypographyProps extends TextBase {
  defaultStyle: string;
}

export const Typography = ({
  text,
  defaultStyle,
  onClick = () => null,
  style = "",
}: TypographyProps) => {
  return (
    <span
      onClick={() => onClick()}
      className={`text-left tracking-[0.05em] ${defaultStyle} ${style}`}
    >
      {text}
    </span>
  );
};

export const TextH1 = (props: TextBase) => (
  <Typography defaultStyle="lg:text-6xl font-light sm:text-m-xl" {...props} />
);
export const TextH2 = (props: TextBase) => (
  <Typography defaultStyle="md:text-d-xl sm:text-m-l" {...props} />
);

export const TextH2MobileImmune = (props: TextBase) => (
  <Typography defaultStyle="text-d-xl" {...props} />
);

export const TextH3 = (props: TextBase) => (
  <Typography defaultStyle="text-d-m font-normal" {...props} />
);

export const TextH4 = (props: TextBase) => (
  <Typography defaultStyle="text-d-s font-medium" {...props} />
);

export const TextH5 = (props: TextBase) => (
  <Typography defaultStyle="lg:text-d-xs sm:text-m-s font-medium" {...props} />
);

export const TextH6 = (props: TextBase) => (
  <Typography defaultStyle="text-d-xs font-normal" {...props} />
);

export const Text = (props: TextBase) => (
  <Typography defaultStyle="text-sm font-normal" {...props} />
);
