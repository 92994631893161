import { ReactElement } from "react";
import { Trans } from "react-i18next";

const Errors = {
  invalidEmail: (
    <Trans key="error.invalidEmail">Не правильный адрес почты</Trans>
  ),
  userNotFound: <Trans key="error.userNotFound">Пользователь не найден</Trans>,
  wrongPassword: <Trans key="error.wrongPassword">Не верный пароль</Trans>,
  weakPassword: <Trans key="error.weakPassword">Пароль слишком короткий</Trans>,
  emptyFields: <Trans key="error.emptyFields">Поле не может быть пустым</Trans>,
  passwordNotMatch: (
    <Trans key="error.passwordNotMatch">Пароли не совпадают</Trans>
  ),
};

export enum errorsFields {
  email = "email",
  password = "password",
  passwordConfirmation = "passwordConfirmation",
}

export interface ErrorObject {
  message: ReactElement | string;
  type: errorsFields | string;
}

export const parseError = (error: string): ErrorObject => {
  if (error.match("invalid-email"))
    return { message: Errors.invalidEmail, type: errorsFields.email };

  if (error.match("user-not-found"))
    return {
      message: Errors.userNotFound,
      type: "",
    };

  if (error.match("wrong-password"))
    return {
      message: Errors.wrongPassword,
      type: errorsFields.password,
    };

  if (error.match("password-length"))
    return {
      message: Errors.weakPassword,
      type: errorsFields.password,
    };

  if (error.match("empty-fields"))
    return {
      message: Errors.emptyFields,
      type: "",
    };

  if (error.match("password-match")) {
    return {
      message: Errors.passwordNotMatch,
      type: errorsFields.password,
    };
  }

  return {
    message: error,
    type: "",
  };
};
