import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as LineIcon } from "src/assets/line.svg";
import { Languages } from "src/utils/i18n/i18n";

import { setLocale } from "src/utils/local-storage/locale";

import { Text } from "../Typography";

export const LanguageSelector = ({
  containerStyle,
  textStyle,
  color,
}: {
  containerStyle: string;
  textStyle: string;
  color: string;
}) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    setLocale(language);
    i18n.changeLanguage(language);
  };

  const checkActive = (language: string) => i18n.language === language;

  return (
    <div className={`flex-row items-center space-x-1.5 ${containerStyle}`}>
      <Text
        style={`${textStyle} ${
          checkActive(Languages.ua) ? "font-bold" : "font-light"
        }`}
        onClick={() => changeLanguage(Languages.ua)}
        text={t("languageSelector.ukr", "Укр")}
      />
      <LineIcon color={color} />
      <Text
        style={`${textStyle} ${
          checkActive(Languages.ru) ? "font-bold" : "font-light"
        }`}
        onClick={() => changeLanguage(Languages.ru)}
        text={t("languageSelector.rus", "Рус")}
      />
    </div>
  );
};
